import React from "react";

const Footer = () => {
  return (
    <div className="w-full bg-slate-100 text-gray-900 py-8 px-4">
      <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-4 border-b border-gray-300 py-8 gap-8">
        {/* Home Section */}
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2">
            <a href="https://bicare.co.nz" aria-label="bicare">
              HOME
            </a>
          </div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/about-us">About Us</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/repayment-calculator">Repayment Calculator</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/borrowing-calculator">Borrowing Calculator</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/paye-calculator">PAYE Calculator</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/rates-compare">Compare Rates</a>
            </li>
          </ul>
        </div>

        {/* Services Section */}
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2">Services</div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/home-loans">Home Loans</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/commercial-loans">Commercial Loans</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/asset-finance">Asset Financing</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/construction-loans">Construction Loans</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/financial-advice">Financial Advice</a>
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2">Contact</div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="tel:096251234">09 625 1234</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="mailto:info@bicare.co.nz">info@bicare.co.nz</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a target="_blank" rel="noopener noreferrer" href="/booking">
                Book a Chat
              </a>
            </li>
          </ul>
        </div>

        {/* Legal Section */}
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2">Legal</div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/disclosure">Disclosure</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col max-w-7xl px-2 py-4 mx-auto justify-between sm:flex-row text-center font-light text-gray-900">
        <p className="py-4">Bicare Finance © 2024 All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
