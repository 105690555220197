import React from "react";

const Footer = () => {
  return (
    <div className="w-full bg-slate-100 text-gray-900 py-8 px-4">
      <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-4 border-b border-gray-300 py-8 gap-8">
        {/* 首页 Section */}
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2">
            <a href="https://bicare.co.nz" aria-label="bicare">
              首页
            </a>
          </div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/about-us">公司团队信息</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/repayment-calculator">还款计算器</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/borrowing-calculator">贷款计算器</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/paye-calculator">PAYE新西兰税后工资计算器</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/rates-compare">各银行贷款利息比较</a>
            </li>
          </ul>
        </div>

        {/* 服务项目 Section */}
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2">服务项目</div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/home-loans">住房贷款</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/commercial-loans">商业贷款</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/asset-finance">资产融资</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/construction-loans">建房开发融资</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/financial-advice">专业房产理财建议</a>
            </li>
          </ul>
        </div>

        {/* 联系方式 Section */}
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2">联系方式</div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="tel:096251234">09 625 1234</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="mailto:info@bicare.co.nz">info@bicare.co.nz</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a target="_blank" rel="noopener noreferrer" href="/booking">
                预约通话
              </a>
            </li>
          </ul>
        </div>

        {/* 法律条款 Section */}
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2">法律条款</div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/privacy-policy">隐私权声明</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/disclosure">披露声明</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col max-w-7xl px-2 py-4 mx-auto justify-between sm:flex-row text-center font-light text-gray-900">
        <p className="py-4">Bicare Finance © 2024 版权所有。</p>
      </div>
    </div>
  );
};

export default Footer;
