import React, { useEffect } from "react";
import tw from "twin.macro";
import AOS from "aos";
import "aos/dist/aos.css";
import { ReactComponent as StarIconBase } from "../assets/review/star.svg";

const HighlightedText = tw.span`text-[#ed1c24]`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto mt-12`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ReviewColumn = tw(
  Column,
)`flex flex-wrap justify-center grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10 lg:gap-10 md:gap-8 sm:gap-2 px-6`;
const ReviewContainer = tw.div``;
const ReviewBlock = tw.div`flex flex-col gap-y-6 sm:gap-y-8`;
const Review = tw.div`relative rounded-2xl bg-white border border-gray-200 shadow-md p-6`;
const ReviewQuoteDeco = tw.div`absolute top-6 left-6 fill-slate-100`;
const ReviewContent = tw.p`text-base font-light tracking-tight text-gray-500`;
const ReviewAuthor = tw.div`relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6`;
const ReviewAuthorName = tw.div`font-light text-base text-gray-900`;
const ReviewAuthorRole = tw.div`mt-1 text-sm text-gray-500`;
const HeadingContainer = tw.div`mb-4`;
const Heading = tw.h2`text-2xl sm:text-4xl lg:text-5xl text-gray-900 font-bold tracking-wide text-center`;
const Subheading = tw.h3`text-base md:text-base lg:text-lg text-center text-gray-700 font-light mt-8 tracking-wider`;
const StarIcon = tw(
  StarIconBase,
)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;

const testimonials = [
  [
    {
      content:
        "Would like to give a big thanks Arlen & to your company Bicare for your excellent service & support. Your focus on the small details are second to none which made my choice extremely easy. Your quick & transparent responses together with your very close connections with the banking industry saved my family both time & money & for this you have now a “forever” customer.",
      author: {
        name: "Iain Hilton",
        role: "CEO | Chester Pluming",
      },
    },
    {
      content:
        "I am very satisfied with the efficiency of Bicare’s service. Whenever I recommend a client, they always contact the client as soon as possible and help them obtain the required loan quickly. My clients have never missed out on their desired property due to loan issues. I recommend clients to Arlen Wang with complete peace of mind.",
      author: {
        name: "Yiwei",
        role: "Gold Medal Agent",
      },
    },
    {
      content:
        "I was recommended to use Bicare for my home loan. Arlen and his team were amazing. Without there help we would not have been able to purchase our new house. Very professional and fast response time. Highly Recommended. A+.",
      author: {
        name: "Royce Clark",
        role: "",
      },
    },
  ],
  [
    {
      content:
        "New Zealand is like my second home. The blue sky and white clouds here inspire me. I especially hope to have a home of my own in this homeland of white clouds. Bicare helped me achieve my dream. No matter how busy my work and social life is in China, my home in New Zealand always brings me peace. Thanks to Bicare!",
      author: {
        name: "Lei",
        role: "Director, Agent",
      },
    },
    {
      content:
        "All of my trucks were financed by Arlen and his Bicare team. Now all of my trucks are fully booked and in high demand. Arlen not only helped me obtain 90% of the loan, but also provided me with many good financial ideas. This not only allowed me to have a fleet of trucks, but also saved me a lot of money in daily operations. It's amazing.",
      author: {
        name: "Leo",
        role: "NZ Solid Limited",
      },
    },
    {
      content:
        "Before working with Bicare, I had already bought several investment properties. When I sold the properties, the bank forced me to repay the loan. My previous mortgage broker told me that this was normal. It wasn't until I met Arlen that he analyzed my situation and found that my mortgage efficiency was too low, and there was a serious equity waste problem. After a year and a half of adjustment, I managed to come out mortgage-free with two properties, including our own residence, without repaying the bank a single penny. This is absolutely amazing.",
      author: {
        name: "William",
        role: "Director | NZX listing firm",
      },
    },
  ],
];

const Reviews = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <Container>
      <Content>
        <HeadingContainer data-aos="fade-up">
          <Heading>
            Loved By Our <HighlightedText>Clients.</HighlightedText>
          </Heading>
          <Subheading>
            Discover what our customers are saying about us and read their
            experiences with our services.
          </Subheading>
        </HeadingContainer>
        <TwoColumn>
          <ReviewColumn>
            {testimonials.map((column, columnIndex) => (
              <ReviewContainer key={columnIndex}>
                <ReviewBlock>
                  {column.map((testimonial, testimonialIndex) => (
                    <ReviewContainer key={testimonialIndex} data-aos="zoom-in">
                      <Review>
                        <ReviewQuoteDeco>
                          <svg aria-hidden="true" width={105} height={78}>
                            <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
                          </svg>
                        </ReviewQuoteDeco>
                        <blockquote className="relative">
                          <ReviewContent>{testimonial.content}</ReviewContent>
                        </blockquote>
                        <ReviewAuthor>
                          <ReviewContainer>
                            <ReviewAuthorName>
                              {testimonial.author.name}
                            </ReviewAuthorName>
                            <ReviewAuthorRole>
                              {testimonial.author.role}
                            </ReviewAuthorRole>
                          </ReviewContainer>
                          <ReviewContainer>
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                          </ReviewContainer>
                        </ReviewAuthor>
                      </Review>
                    </ReviewContainer>
                  ))}
                </ReviewBlock>
              </ReviewContainer>
            ))}
          </ReviewColumn>
        </TwoColumn>
      </Content>
    </Container>
  );
};

export default Reviews;
