import React, { useState, useEffect } from "react";
import { $ } from "react-jquery-plugin";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import AboutUsCN from "./pages/AboutUsCN";
import AssetFinance from "./pages/services/AssetFinance";
import AssetFinanceCN from "./pages/services/AssetFinanceCN";
import Booking from "./pages/Booking";
import BookingCN from "./pages/BookingCN";
import BorrowingCalculator from "./pages/calculator/BorrowingCalculator";
import BorrowingCalculatorCN from "./pages/calculator/BorrowingCalculatorCN";
import CommercialLoan from "./pages/services/CommercialLoan";
import CommercialLoanCN from "./pages/services/CommercialLoanCN";
import ConstructionLoans from "./pages/services/ConstructionLoans";
import ConstructionLoansCN from "./pages/services/ConstructionLoansCN";
import Contact from "./pages/Contact";
import ContactCN from "./pages/ContactCN";
import Cookies from "./pages/Cookies";
import CookiesCN from "./pages/CookiesCN";
import Disclosure from "./pages/Disclosure";
import FinancialAdvice from "./pages/services/FinancialAdvice";
import FinancialAdviceCN from "./pages/services/FinancialAdviceCN";
import Form from "./pages/Form";
import FormCN from "./pages/FormCN";
import Footer from "./pages/Footer";
import FooterCN from "./pages/FooterCN";
import Home from "./pages/Home";
import HomeCN from "./pages/HomeCN";
import HomeLoan from "./pages/services/HomeLoan";
import HomeLoanCN from "./pages/services/HomeLoanCN";
import Navbar from "./pages/Navbar";
import NavbarCN from "./pages/NavbarCN";
import NotFound from "./pages/Error";
import NotFoundCN from "./pages/ErrorCN";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RepaymentCalculator from "./pages/calculator/RepaymentCalculator";
import RepaymentCalculatorCN from "./pages/calculator/RepaymentCalculatorCN";
import RatesCompare from "./pages/calculator/RatesCompare";
import RatesCompareCN from "./pages/calculator/RatesCompareCN";
import PayeCalculator from "./pages/calculator/PayeCalculator";
import PayeCalculatorCN from "./pages/calculator/PayeCalculatorCN";
import ScrollToTop from "./components/ScrollToTop";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);
const App = () => {
  const [language, setLanguage] = useState(() => {
    const lang = localStorage.getItem("SITE_LANGUAGE");
    const iniLang = JSON.parse(lang);
    return iniLang || "zh-cn";
  });

  const handleClick = (e) => {
    setLanguage(e);
  };

  useEffect(() => {
    console.log(language);
    localStorage.setItem("SITE_LANGUAGE", JSON.stringify(language));
  }, [language]);

  useEffect(() => {
    $("#loader-wrapper").fadeOut();
  }, []);

  return (
    <Router>
      {language === "en" ? (
        <Navbar handleClick={handleClick} />
      ) : (
        <NavbarCN handleClick={handleClick} />
      )}
      <Routes>
        {language === "en" ? (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/home-loans" element={<HomeLoan />} />
            <Route path="/commercial-loans" element={<CommercialLoan />} />
            <Route path="/asset-finance" element={<AssetFinance />} />
            <Route path="/construction-loans" element={<ConstructionLoans />} />
            <Route path="/financial-advice" element={<FinancialAdvice />} />
            <Route path="/application-form" element={<Form />} />
            <Route
              path="/repayment-calculator"
              element={<RepaymentCalculator />}
            />
            <Route
              path="/borrowing-calculator"
              element={<BorrowingCalculator />}
            />
            <Route path="/paye-calculator" element={<PayeCalculator />} />
            <Route path="/rates-compare" element={<RatesCompare />} />
            <Route path="*" element={<NotFound />} />
          </>
        ) : (
          <>
            <Route path="/" element={<HomeCN />} />
            <Route path="/about-us" element={<AboutUsCN />} />
            <Route path="/contact-us" element={<ContactCN />} />
            <Route path="/booking" element={<BookingCN />} />
            <Route path="/home-loans" element={<HomeLoanCN />} />
            <Route path="/commercial-loans" element={<CommercialLoanCN />} />
            <Route path="/asset-finance" element={<AssetFinanceCN />} />
            <Route path="/financial-advice" element={<FinancialAdviceCN />} />
            <Route path="/application-form" element={<FormCN />} />
            <Route
              path="/construction-loans"
              element={<ConstructionLoansCN />}
            />
            <Route
              path="/repayment-calculator"
              element={<RepaymentCalculatorCN />}
            />
            <Route
              path="/borrowing-calculator"
              element={<BorrowingCalculatorCN />}
            />
            <Route path="/paye-calculator" element={<PayeCalculatorCN />} />
            <Route path="/rates-compare" element={<RatesCompareCN />} />
            <Route path="*" element={<NotFoundCN />} />
          </>
        )}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclosure" element={<Disclosure />} />
      </Routes>

      {language === "en" ? <Cookies /> : <CookiesCN />}
      <ScrollToTop />
      {language === "en" ? <Footer /> : <FooterCN />}
    </Router>
  );
};

export default App;
