import React, { Fragment } from "react";
import { Popover, Disclosure, Transition } from "@headlessui/react";
import {
  WrenchScrewdriverIcon,
  BanknotesIcon,
  Bars3Icon,
  BuildingOffice2Icon,
  ChevronUpIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronDownIcon,
  XMarkIcon,
  HomeIcon,
  CalendarDaysIcon,
  CurrencyDollarIcon,
  ReceiptPercentIcon,
  CalculatorIcon,
  PhoneIcon,
  CalendarIcon,
  // PuzzlePieceIcon,
} from "@heroicons/react/24/outline";
import LogoEN from "../assets/logo/logo-en.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const services = [
  {
    name: "Home Loans",
    description:
      "We offer personalized service and tailored solutions to help you purchasing properties.",
    href: "/home-loans",
    icon: HomeIcon,
  },
  {
    name: "Commercial Loans",
    description:
      "We offer competitive rates, flexible repayment terms to help you achieve your business goal.",
    href: "/commercial-loans",
    icon: BuildingOffice2Icon,
  },
  {
    name: "Construction Loans",
    description:
      "Whether you're looking to build a single residential house or master planning a staged subdivision, we get you covered.",
    href: "/construction-loans",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Asset Financing",
    description:
      "We offer a variety of asset financing solutions to help you obtain the necessary funding to help your business succeed.",
    href: "/asset-finance",
    icon: BanknotesIcon,
  },
  {
    name: "Financial Advice",
    description:
      "Helping you develop suitable investment strategies and plans.",
    href: "/financial-advice",
    icon: ChatBubbleBottomCenterTextIcon,
  },
];
const calculators = [
  {
    name: "Repayment Calculator",
    description:
      "Estimate how much you could be paying for your repayment mortgage.",
    href: "/repayment-calculator",
    icon: CalendarDaysIcon,
  },
  {
    name: "Borrowing Calculator",
    description:
      "Find out how much you could borrow based on your current financial situation.",
    href: "/borrowing-calculator",
    icon: CalculatorIcon,
  },
  {
    name: "PAYE Calculator",
    description: "Estimate how much you could take home after paying tax.",
    href: "/paye-calculator",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Compare Interest Rates",
    description: "Check out a summary of the latest home loan interest rates.",
    href: "/rates-compare",
    icon: ReceiptPercentIcon,
  },
];
function Navbar(props) {
  return (
    <Disclosure
      as="nav"
      className="w-full bg-white bg-opacity-95 border-b-0 border-gray-70 backdrop-filter fixed z-10"
    >
      {({ open }) => (
        <>
          <div className="px-2 sm:px-6 lg:px-8 border-b border-gray-200">
            <div className="relative flex h-20 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className="inline-flex items-center justify-center rounded-md p-2 rounded-lg p-2 hover:bg-gray-200"
                  aria-label="MobileMenu"
                >
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                {/*logo*/}
                <div className="flex flex-shrink-0 items-center">
                  <a href="/" aria-label="bicare">
                    <img
                      className="block h-20 w-20 lg:hidden"
                      title="Bicare loan mortgage broker"
                      src={LogoEN}
                      alt="Bicare finance logo english, mortgage broker new zealand"
                      loading="lazy"
                    />
                  </a>
                  <a href="/" aria-label="bicare">
                    <img
                      className="hidden h-20 w-20 lg:block"
                      title="Bicare loan broker"
                      src={LogoEN}
                      alt="Bicare finance logo english, mortgage broker new zealand"
                      loading="lazy"
                    />
                  </a>
                </div>
                {/*navlinks*/}
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8 ">
                  {/*services popover*/}
                  <Popover.Group
                    as="div"
                    className="font-light inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md text-gray-900 hover:text-[#ed1c24] "
                  >
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-stone-800" : "text-stone-800",
                              "group inline-flex items-center rounded-md border-none text-base focus:outline-none hover:text-[#ed1c24]",
                            )}
                          >
                            <span>Services</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-500" : "text-gray-400",
                                "ml-1 h-5 w-5 group-hover:text-[#ed1c24]",
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-10 -ml-4 mt-6 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-16 lg:-translate-x-1/2">
                              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  {services.map((item) => (
                                    <a
                                      key={item.name}
                                      href={item.href}
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    >
                                      <item.icon
                                        className="h-12 w-12 items-center flex-shrink-0 border-8 border-[#fff1f2] bg-[#fff1f2] rounded-lg text-[#ed1c24] text-base font-light"
                                        aria-hidden="true"
                                      />
                                      <div className="ml-4">
                                        <h2 className="text-base font-normal text-gray-900">
                                          {item.name}
                                        </h2>
                                        <p className="mt-1 text-sm text-gray-500">
                                          {item.description}
                                        </p>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </Popover.Group>
                  <a
                    href="/application-form"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md font-light text-gray-900 hover:text-[#ed1c24]"
                  >
                    Application Form
                  </a>
                  <Popover.Group
                    as="div"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md font-light text-gray-900 hover:text-[#ed1c24]"
                  >
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-stone-800" : "text-stone-800",
                              "group inline-flex items-center rounded-md border-none text-base focus:outline-none hover:text-[#ed1c24]",
                            )}
                          >
                            <span>Financial Calculators</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-500" : "text-gray-400",
                                "ml-1 h-5 w-5 group-hover:text-[#ed1c24]",
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-10 -ml-4 mt-6 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  {calculators.map((item) => (
                                    <a
                                      key={item.name}
                                      href={item.href}
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    >
                                      <item.icon
                                        className="h-12 w-12 items-center flex-shrink-0 border-8 border-[#fff1f2] bg-[#fff1f2] rounded-lg text-[#ed1c24] text-base font-light"
                                        aria-hidden="true"
                                      />
                                      <div className="ml-4">
                                        <h3 className="text-base font-normal text-gray-900">
                                          {item.name}
                                        </h3>
                                        <p className="mt-1 text-sm text-gray-500">
                                          {item.description}
                                        </p>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </Popover.Group>
                  <a
                    href="/about-us"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md font-light text-gray-900 hover:text-[#ed1c24]"
                  >
                    About Us
                  </a>
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* language selection */}
                <button
                  className="mr-3 lg:block inline-flex items-center"
                  onClick={() => props.handleClick("zh-cn")}
                >
                  中文
                </button>
                <a
                  href="/contact-us"
                  className="hidden lg:block inline-flex items-center border-transparent text-md font-light text-white rounded-3xl bg-[#ed1c24] px-4 py-2"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden bg-white">
            <Disclosure className="sm:hidden bg-gray-100">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center border-l-4 border-l-transparent border-b border-gray-200 py-2 pl-3 pr-4 text-base font-light text-gray-900">
                    Services
                    {open ? (
                      <ChevronUpIcon
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-6 pt-2 pb-4 text-sm border-b border-gray-200">
                    <Disclosure.Button
                      as="a"
                      href="/home-loans"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      Home Loans
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/commercial-loans"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      Commercial Loans
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/construction-loans"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      Construction Loans
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/asset-finance"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      Asset Financing
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/financial-advice"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      Financial Advice
                    </Disclosure.Button>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {/*Financial Calculators*/}
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center border-l-4 border-l-transparent border-b border-gray-200 py-2 pl-3 pr-4 text-base font-light text-gray-900">
                    Financial Calculators
                    {open ? (
                      <ChevronUpIcon
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-6 pt-2 pb-4 text-sm border-b border-gray-200">
                    <Disclosure.Button
                      as="a"
                      href="/repayment-calculator"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      Repayment Calculator
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/borrowing-calculator"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      Borrowing Calculator
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/paye-calculator"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      PAYE Calculator
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/rates-compare"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      Compare Interest Rates
                    </Disclosure.Button>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure.Button
              as="a"
              href="/application-form"
              className="flex w-full justify-between items-center border-l-4 border-l-transparent border-b border-gray-200 py-2 pl-3 pr-4 text-base font-light text-gray-900"
            >
              Application Form
            </Disclosure.Button>

            <Disclosure.Button
              as="a"
              href="/about-us"
              className="flex w-full justify-between items-center border-l-4 border-l-transparent py-2 pl-3 pr-4 text-base font-light text-gray-900"
            >
              About Us
            </Disclosure.Button>

            <div className="justify-start grid grid-cols-2 border border-gray-200 bg-slate-100">
              <Disclosure.Button
                as="a"
                href="/booking"
                className="flex w-auto justify-start items-center py-2 pl-2 pr-4 text-base font-light text-gray-900"
              >
                <CalendarIcon className="text-gray-400 w-6 h-6 m-1" />
                Book An Appointment
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/contact-us"
                className="flex w-full justify-center items-center border-l border-t-transparent border-l-gray-200 py-2 pl-3 pr-4 text-base font-light text-gray-900"
              >
                <PhoneIcon className="text-gray-400 w-6 h-6 m-2" />
                Contact Us
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export default Navbar;
